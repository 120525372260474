import { browser } from '$app/environment';
import type { paths } from './api/interfaces.generated.js';

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
export const BUILD_ID: string | null = import.meta.env.VITE_BUILD_ID
  ? String(import.meta.env.VITE_BUILD_ID)
  : null;
export const ENABLE_MAINTENANCE_MODE: boolean =
  import.meta.env.VITE_ENABLE_MAINTENANCE_MODE === 'true';
export const API_URL = import.meta.env.VITE_API_BASE_URL || '/api';

/** Build and return the URL to an API endpoint */
export function buildApiUrl(
  // eslint-disable-next-line @typescript-eslint/ban-types
  relativePath: keyof paths | (string & {}),
  parameters?: Record<string, string>,
) {
  const pathWithQueryString = parameters
    ? `${relativePath}?${new URLSearchParams(parameters).toString()}`
    : relativePath;

  return API_URL.startsWith('/')
    ? (`${API_URL}${pathWithQueryString}` as const)
    : new URL(pathWithQueryString, API_URL).href;
}

export const DATA_PRIVACY_URL =
  'https://docs.google.com/document/u/1/d/1a_YBPckUv-DS_ZaDa6hHwWCLDteGu6tA--I1BQ1YLq0';
export const TERMS_AND_CONDITIONS_URL =
  'https://docs.google.com/document/d/1ZznXIgsEkm576TpQn99VZJ4xM0QUcjLQ';

const mainSite = 'https://pome.gr' as const;

/** The URL to the base of the website. */
const frontendBaseUrl = new URL(import.meta.env.VITE_FRONTEND_BASE || mainSite);

export const IS_MAIN_SITE = browser && window.origin === mainSite;

/**
 * Build and return a full URL using the frontend URL as the root for `path`.
 *
 * @example
 * canonicalUrlForPath('/foo') // https://pome.gr/foo
 */
export function canonicalUrlForPath(path: string) {
  return new URL(path, frontendBaseUrl);
}
